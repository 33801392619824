<template>
  <div>
    <b-card class="mb-4">
      <b-row>
        <b-col>
          <b-form-group class="mb-0">
            <b-input-group>
              <b-select id="selectCalendar" v-model="calendarId">
                <template #first>
                  <b-form-select-option :value="null" disabled
                    >-- Selecione o calendário que deseja visualizar
                    --</b-form-select-option
                  >
                </template>
                <b-form-select-option
                  v-for="calendar in calendars"
                  :key="calendar.id"
                  :value="calendar.id"
                >
                  {{ calendar.patient }}
                </b-form-select-option>
              </b-select>
              <b-input-group-append>
                <b-button
                  variant="primary"
                  @click="loadEvents"
                  class="font-weight-bold"
                >
                  <i class="fa fa-search"></i>
                  BUSCAR EVENTOS
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col class="d-flex justify-content-end align-items-end">
          <div>
            <b-button
              variant="success"
              @click="addEvent"
              class="font-weight-bold"
            >
              <i class="fa fa-plus"></i>
              NOVO EVENTO
            </b-button>
          </div>
        </b-col>
      </b-row>

      <b-row class="mt-2">
        <b-col>
          <b-form-group class="mb-0">
            <b-form-checkbox id="showDeleted" v-model="showDeleted" size="lg"
              >Exibir eventos cancelados</b-form-checkbox
            >
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>

    <b-card>
      <div class="d-flex justify-content-between mb-4">
        <p>
          <b>Atenção:</b> Ao clicar em um evento, você poderá visualizar,
          atualizar ou excluir o mesmo.
        </p>

        <p class="mb-0">
          <b>Legenda:</b>
          <span class="ml-2">
            <i class="fa fa-square" style="color: #28a745"></i> Confirmado
          </span>
          <span class="ml-2">
            <i class="fa fa-square" style="color: #ffc107"></i> Tentativa
          </span>
          <span class="ml-2">
            <i class="fa fa-square" style="color: #dc3545"></i> Cancelado
          </span>
        </p>
      </div>
      <FullCalendar id="calendar" ref="calendar" :options="calendarOptions" />
    </b-card>

    <b-modal
      id="mdlEventDetail"
      size="lg"
      hide-footer
      @shown="scrollChat"
      @hidden="stopChatUpdate"
    >
      <template #modal-header="{ cancel }">
        <div class="d-flex justify-content-between align-items-center w-100">
          <h5 class="m-0">Detalhes do Evento</h5>
          <div class="text-right">
            <b-button
              size="sm"
              class="mr-2"
              variant="light-danger"
              @click="deleteEvent"
              v-if="!event.deleted"
            >
              <BIconTrashFill />
            </b-button>

            <b-button
              size="sm"
              class="mr-2"
              @click.prevent="editEvent(eventDetail.info)"
            >
              <BIconPencilFill />
            </b-button>

            <b-button size="sm" class="mr-2" @click="cancel">
              <BIconXLg />
            </b-button>
          </div>
        </div>
      </template>

      <div class="row">
        <div class="col-6">
          <div class="form-group mb-2">
            <label class="mb-0">Nome do paciente:</label>
            <p class="form-control-plaintext pt-0 pb-0 text-dark-50">
              {{ eventDetail.patient }}
            </p>
          </div>
        </div>

        <div class="col-6">
          <div class="form-group mb-2">
            <label class="mb-0">Email:</label>
            <p class="form-control-plaintext pt-0 pb-0 text-dark-50">
              {{ eventDetail.email }}
            </p>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-4">
          <div class="form-group mb-2">
            <label class="mb-0">Whatsapp:</label>
            <p class="form-control-plaintext pt-0 pb-0 text-dark-50">
              {{ eventDetail.whatsapp }}
            </p>
          </div>
        </div>

        <div class="col-4">
          <div class="form-group mb-2">
            <label class="mb-0">Data do agendamento:</label>
            <p class="form-control-plaintext pt-0 pb-0 text-dark-50">
              {{ eventDetail.scheduling }}
            </p>
          </div>
        </div>

        <div class="col-4">
          <div class="form-group mb-2">
            <label class="mb-0">Status:</label>
            <p class="form-control-plaintext pt-0 pb-0">
              <b-badge
                v-if="eventDetail.status === 'confirmed'"
                variant="success"
                >Confirmado</b-badge
              >
              <b-badge
                v-if="eventDetail.status === 'tentative'"
                variant="warning"
                >Tentativa</b-badge
              >
              <b-badge
                v-if="eventDetail.status === 'cancelled'"
                variant="danger"
                >Cancelado</b-badge
              >
            </p>
          </div>
        </div>
      </div>

      <div class="form-group mb-2">
        <label class="mb-0">Descrição:</label>
        <p class="form-control-plaintext pt-0 pb-0 text-dark-50">
          {{ eventDetail.description }}
        </p>
      </div>

      <div class="row">
        <div class="col-6">
          <div class="form-group mb-2">
            <label class="mb-0">Nome do setor:</label>
            <p class="form-control-plaintext pt-0 pb-0 text-dark-50">
              {{ eventDetail.sector }}
            </p>
          </div>
        </div>

        <div class="col-6">
          <div class="form-group mb-2">
            <label class="mb-0">Nome da unidade:</label>
            <p class="form-control-plaintext pt-0 pb-0 text-dark-50">
              {{ eventDetail.unit }}
            </p>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <div class="form-group mb-2">
            <label class="mb-0">Nome do prestador:</label>
            <p class="form-control-plaintext pt-0 pb-0 text-dark-50">
              {{ eventDetail.provider }}
            </p>
          </div>
        </div>

        <div class="col-6">
          <div class="form-group mb-2">
            <label class="mb-0">Nome do procedimento:</label>
            <p class="form-control-plaintext pt-0 pb-0 text-dark-50">
              {{ eventDetail.procedure }}
            </p>
          </div>
        </div>
      </div>

      <div class="card border mt-3">
        <!--begin::Header-->
        <div class="card-header font-weight-bolder p-4">Chat</div>
        <div
          class="card-body"
          style="position: relative; height: 300px; overflow-y: scroll"
          id="bodyChat"
        >
          <!--begin::Scroll-->

          <div
            :msg="msg"
            :key="msg.id"
            v-for="msg in eventDetail.messages"
            class="mb-3"
          >
            <div
              class="d-flex flex-column align-items-center m-5"
              v-if="msg.configuracoes.exibirData"
            >
              <span class="label label-light-info label-inline label-xl">{{
                msg.dataformatada
              }}</span>
            </div>

            <div class="d-flex flex-column" :class="msg.configuracoes.tipo">
              <div
                class="d-flex align-items-center"
                v-if="msg.configuracoes.exibirCabecalho"
              >
                <div class="symbol symbol-circle symbol-30 mr-3">
                  <img alt="Pic" src="/media/users/blank.png" />
                </div>
                <div>
                  <span class="text-dark-75 font-weight-bold">{{
                    msg.numero
                  }}</span>
                  <span class="text-muted font-size-sm d-block">{{
                    msg.login
                  }}</span>
                </div>
              </div>
              <div
                class="mt-2 rounded text-dark text-left max-w-400px min-w-150px"
                :class="msg.configuracoes.cor"
              >
                <template v-if="msg.configuracoes.exibirImagem">
                  <img
                    :src="msg.link"
                    style="max-width: 100%"
                    class="p-2 rounded-lg"
                    @error="msg.configuracoes.exibirImagem = false"
                  />
                </template>
                <p
                  class="mb-0 pb-0 pl-2 pr-2 pt-2"
                  style="white-space: pre-wrap"
                  v-html="
                    msg.configuracoes.exibirImagem
                      ? msg.configuracoes.conteudo
                      : msg.configuracoes.conteudoCompleto
                  "
                ></p>
                <p
                  class="mb-0 pb-2 pl-2 pr-2 pt-0 text-right font-size-xs text-dark-50"
                >
                  {{ msg.hora }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <!--begin::Footer-->
        <div class="card-footer p-4 bg-gray-100">
          <div class="form-group p-0 m-0">
            <div class="input-group">
              <input
                type="text"
                class="form-control"
                placeholder="Digite a mensagem..."
                v-model="message"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-primary"
                  type="button"
                  @click="sendMessage"
                >
                  Enviar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal
      id="mdlEvent"
      @shown="validacao"
      :title="event.id ? 'Atualizar Evento' : 'Cadastrar Evento'"
      size="lg"
    >
      <b-row>
        <b-col>
          <b-form-group
            label="Nome do paciente:"
            label-for="eventPatient"
            invalid-feedback="Campo obrigatório!"
            :state="states.patient"
            class="mb-3"
          >
            <b-form-input
              id="eventPatient"
              v-model="event.patient"
              name="patient"
              :state="states.patient"
              trim
            ></b-form-input>
          </b-form-group>
        </b-col>

        <b-col>
          <b-form-group
            label="Email:"
            label-for="eventEmail"
            invalid-feedback="Campo obrigatório!"
            :state="states.email"
            class="mb-3"
          >
            <b-input
              id="eventEmail"
              v-model="event.email"
              name="email"
              :state="states.email"
              trim
            ></b-input>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group
            label="Whatsapp:"
            label-for="eventWhatsapp"
            invalid-feedback="Campo obrigatório!"
            :state="states.whatsapp"
            class="mb-3"
          >
            <b-input
              id="eventWhatsapp"
              v-model="event.whatsapp"
              name="whatsapp"
              :state="states.whatsapp"
              trim
              data-inputmask="'mask': ['(99) 9999-9999', '(99) 99999-9999']"
            ></b-input>
          </b-form-group>
        </b-col>

        <b-col>
          <b-form-group
            label="Data do agendamento:"
            label-for="eventDate"
            invalid-feedback="Campo obrigatório!"
            :state="states.scheduling"
            class="mb-3"
          >
            <b-form-input
              id="eventDate"
              type="datetime-local"
              v-model="event.scheduling"
              name="scheduling"
              :state="states.scheduling"
              trim
            ></b-form-input>
          </b-form-group>
        </b-col>

        <b-col>
          <b-form-group
            label="Status:"
            label-for="eventStatus"
            invalid-feedback="Campo obrigatório!"
            :state="states.status"
            class="mb-3"
          >
            <b-select
              id="eventStatus"
              v-model="event.status"
              :state="states.status"
            >
              <b-form-select-option
                v-for="status in statuses"
                :key="status.id"
                :value="status.id"
              >
                {{ status.name }}
              </b-form-select-option>
            </b-select>
          </b-form-group>
        </b-col>
      </b-row>

      <b-form-group
        label="Descrição:"
        label-for="eventDescription"
        invalid-feedback="Campo obrigatório!"
        :state="states.description"
        class="mb-3"
      >
        <b-input
          id="eventDescription"
          v-model="event.description"
          name="description"
          :state="states.description"
          trim
        ></b-input>
      </b-form-group>

      <b-row>
        <b-col>
          <b-form-group
            label="Nome do setor:"
            label-for="eventSector"
            invalid-feedback="Campo obrigatório!"
            :state="states.sector"
            class="mb-3"
          >
            <b-input
              id="eventSector"
              v-model="event.sector"
              name="sector"
              :state="states.sector"
              trim
            ></b-input>
          </b-form-group>
        </b-col>

        <b-col>
          <b-form-group
            label="Nome da unidade:"
            label-for="eventUnit"
            invalid-feedback="Campo obrigatório!"
            :state="states.unit"
            class="mb-3"
          >
            <b-input
              id="eventUnit"
              v-model="event.unit"
              name="unit"
              :state="states.unit"
              trim
            ></b-input>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group
            label="Nome do prestador:"
            label-for="eventProvider"
            invalid-feedback="Campo obrigatório!"
            :state="states.provider"
            class="mb-3"
          >
            <b-input
              id="eventProvider"
              v-model="event.provider"
              name="provider"
              :state="states.provider"
              trim
            ></b-input>
          </b-form-group>
        </b-col>

        <b-col>
          <b-form-group
            label="Nome do procedimento:"
            label-for="eventProcedure"
            invalid-feedback="Campo obrigatório!"
            :state="states.procedure"
            class="mb-3"
          >
            <b-input
              id="eventProcedure"
              v-model="event.procedure"
              name="procedure"
              :state="states.procedure"
              trim
            ></b-input>
          </b-form-group>
        </b-col>
      </b-row>

      <template #modal-footer="{ cancel }">
        <div class="d-flex justify-content-end w-100">
          <b-button
            size="sm"
            variant="secondary"
            @click.prevent="cancel()"
            class="mr-2"
          >
            Cancelar
          </b-button>
          <b-button size="sm" variant="success" @click.prevent="saveEvent()">
            Salvar
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import ptBrLocale from "@fullcalendar/core/locales/pt-br";

import ApiService from "../../../core/services/api.service";
import moment from "moment";
import Inputmask from "inputmask";

import { BIconTrashFill, BIconPencilFill, BIconXLg } from "bootstrap-vue";
import Swal from "sweetalert2";

export default {
  components: {
    FullCalendar,
    BIconTrashFill,
    BIconPencilFill,
    BIconXLg
  },

  data() {
    return {
      calendarEl: null,
      calendar: null,
      calendarOptions: {
        themeSystem: "bootstrap",
        locale: ptBrLocale,
        plugins: [
          interactionPlugin,
          bootstrapPlugin,
          timeGridPlugin,
          listPlugin,
          dayGridPlugin
        ],
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "timeGridWeek,timeGridDay,dayGridMonth"
        },
        initialView: "timeGridWeek",
        events: [],
        eventClick: this.handleEventClick,
        datesSet: this.loadEvents,
        showNonCurrentDates: false
      },
      states: {
        patient: null,
        email: null,
        whatsapp: null,
        scheduling: null,
        description: null,
        sector: null,
        unit: null,
        provider: null,
        procedure: null,
        status: null
      },
      event: {
        id: "",
        patient: "",
        email: "",
        whatsapp: "",
        scheduling: "",
        description: "",
        sector: "",
        unit: "",
        provider: "",
        procedure: "",
        status: "confirmed"
      },
      calendars: [],
      calendarId: null,
      statuses: [
        { id: "confirmed", name: "Confirmado" },
        { id: "tentative", name: "Tentativa" },
        { id: "cancelled", name: "Cancelado" }
      ],
      showDeleted: false,
      eventDetail: {
        id: "",
        patient: "",
        email: "",
        whatsapp: "",
        scheduling: "",
        description: "",
        sector: "",
        unit: "",
        provider: "",
        procedure: "",
        status: "confirmed",
        deleted: false,
        messages: []
      },
      message: "",
      updateChatTimeout: null
    };
  },

  mounted() {
    this.calendar = this.$refs.calendar.getApi();
    this.loadCalendars();
  },

  methods: {
    loadCalendars() {
      ApiService.get("jpr/get-google-calendar-list", "", true, {
        baseURL: process.env.VUE_APP_COMUNICANTE_API,
        headers: {
          Accept: "application/json"
        }
      }).then((response) => {
        this.calendars = response.data.calendars;
        this.calendars.map((calendar) => {
          if (calendar.primary) {
            this.calendarId = calendar.id;
            this.loadEvents();
          }
        });
      });
    },

    loadEvents(dateInfo) {
      if (this.calendars.length == 0 || !this.calendarId) {
        return false;
      }

      if (!dateInfo) {
        dateInfo = this.calendar.getDate();
      }

      ApiService.post(
        "jpr/get-google-calendar-events",
        {
          calendarId: this.calendarId,
          timeMin: dateInfo.startStr,
          timeMax: dateInfo.endStr,
          showDeleted: this.showDeleted ? "true" : "false"
        },
        {
          baseURL: process.env.VUE_APP_COMUNICANTE_API,
          headers: {
            Accept: "application/json"
          }
        }
      ).then((response) => {
        this.calendar.removeAllEvents();

        response.data.events.map((event) => {
          this.calendar.addEvent({
            id: event.id,
            title: event.summary,
            start: event.start.dateTime,
            end: event.end.dateTime,
            originalEvent: event,
            backgroundColor: this.getEventColor(event.status),
            borderColor: this.getEventColor(event.status)
          });
        });
      });
    },

    async handleEventClick(info) {
      this.eventDetail = {
        id: "",
        patient: "",
        email: "",
        whatsapp: "",
        scheduling: "",
        description: "",
        sector: "",
        unit: "",
        provider: "",
        procedure: "",
        status: "confirmed",
        deleted: false,
        messages: []
      };

      const event = info.event;
      const originalEvent = event.extendedProps.originalEvent;

      let json = null;
      try {
        json = JSON.parse(originalEvent.description);
        json.id = originalEvent.id;
        json.scheduling = moment(originalEvent.start.dateTime).format(
          "DD/MM/YYYY HH:mm"
        );
        json.status = originalEvent.status;
        json.deleted = originalEvent.status === "cancelled";
        json.info = info;
      } catch (e) {
        json = {
          id: originalEvent.id,
          patient: originalEvent.summary,
          email: "",
          whatsapp: "",
          scheduling: moment(originalEvent.start.dateTime).format(
            "DD/MM/YYYY HH:mm"
          ),
          description: originalEvent.description,
          sector: "",
          unit: "",
          provider: "",
          procedure: "",
          status: originalEvent.status,
          deleted: originalEvent.status === "cancelled",
          info: info
        };
      }

      this.eventDetail = {
        ...json
      };

      const phone = json.whatsapp.replace(/\D/g, "");

      if (phone.length >= 10) {
        this.updateChatTimeout = true;
        await this.updateChat(phone, true);
      } else {
        json.messages = {};
      }

      this.$bvModal.show("mdlEventDetail");
    },

    editEvent(info) {
      const event = info.event;
      const originalEvent = event.extendedProps.originalEvent;

      let json = null;
      try {
        json = JSON.parse(originalEvent.description);
        json.id = originalEvent.id;
        json.scheduling = moment(originalEvent.start.dateTime).format(
          "YYYY-MM-DDTHH:mm"
        );
        json.status = originalEvent.status;
      } catch (e) {
        json = {
          id: originalEvent.id,
          patient: originalEvent.summary,
          email: "",
          whatsapp: "",
          scheduling: moment(originalEvent.start.dateTime).format(
            "YYYY-MM-DDTHH:mm"
          ),
          description: originalEvent.description,
          sector: "",
          unit: "",
          provider: "",
          procedure: "",
          status: originalEvent.status
        };
      }

      this.showEvent(json);
    },

    addEvent() {
      this.showEvent({
        scheduling: moment()
          .add(1, "hour")
          .startOf("hour")
          .format("YYYY-MM-DDTHH:mm")
      });
    },

    showEvent(event) {
      this.event.id = event.id ?? "";
      this.event.patient = event.patient ?? "";
      this.event.email = event.email ?? "";
      this.event.whatsapp = event.whatsapp ?? "";
      this.event.scheduling = event.scheduling ?? "";
      this.event.description = event.description ?? "";
      this.event.sector = event.sector ?? "";
      this.event.unit = event.unit ?? "";
      this.event.provider = event.provider ?? "";
      this.event.procedure = event.procedure ?? "";
      this.event.status = event.status ?? "confirmed";

      this.states.patient = null;
      this.states.email = null;
      this.states.whatsapp = null;
      this.states.scheduling = null;
      this.states.description = null;
      this.states.sector = null;
      this.states.unit = null;
      this.states.provider = null;
      this.states.procedure = null;
      this.states.status = null;

      this.$forceUpdate();

      this.$bvModal.hide("mdlEventDetail");
      this.$bvModal.show("mdlEvent");
    },

    validacao() {
      const mdlEvent = document.getElementById("mdlEvent");
      Inputmask().mask(mdlEvent.querySelectorAll("input"));
    },

    saveEvent() {
      let formValid = true;

      Object.entries(this.states).forEach(([key]) => {
        if (!this.event[key]) {
          this.states[key] = false;
          formValid = false;
        } else {
          this.states[key] = true;
        }
      });

      if (formValid) {
        const url = this.event.id
          ? `jpr/update-google-calendar-event/${this.event.id}`
          : "jpr/create-google-calendar-event";

        const startObj = {
          dateTime: moment(this.event.scheduling).format("YYYY-MM-DDTHH:mm:ss"),
          timeZone: "America/Bahia"
        };

        const endObj = {
          dateTime: moment(this.event.scheduling)
            .add(30, "minutes")
            .format("YYYY-MM-DDTHH:mm:ss"),
          timeZone: "America/Bahia"
        };

        const json = JSON.stringify(this.event);

        delete json.id;
        delete json.scheduling;
        delete json.status;

        let event = {
          summary: this.event.patient,
          description: json,
          start: startObj,
          end: endObj,
          status: this.event.status
        };

        ApiService.post(url, event, {
          baseURL: process.env.VUE_APP_COMUNICANTE_API,
          headers: {
            Accept: "application/json"
          }
        }).then((res) => {
          const event = res.data.event;

          if (this.event.id) {
            this.calendar.getEventById(this.event.id).remove();
          }

          this.calendar.addEvent({
            id: event.id,
            title: event.summary,
            start: event.start.dateTime,
            end: event.end.dateTime,
            originalEvent: event,
            backgroundColor: this.getEventColor(event.status),
            borderColor: this.getEventColor(event.status)
          });

          this.$bvToast.toast("Evento salvo com sucesso!", {
            title: "Sucesso",
            variant: "success",
            solid: true
          });
        });

        this.$bvModal.hide("mdlEvent");
      } else {
        this.$bvToast.toast("Verifique os campos inválidos!", {
          title: "Atenção",
          variant: "danger",
          solid: true
        });
      }
    },

    deleteEvent() {
      Swal.fire({
        title: "Deseja realmente excluir o evento?",
        text: "Esta ação não poderá ser desfeita!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, excluir!",
        cancelButtonText: "Cancelar"
      }).then(async (result) => {
        if (result.isConfirmed) {
          await ApiService.get(
            `jpr/delete-google-calendar-event/${this.eventDetail.id}`,
            "",
            true,
            {
              baseURL: process.env.VUE_APP_COMUNICANTE_API,
              headers: {
                Accept: "application/json"
              }
            }
          ).then(() => {
            this.calendar.getEventById(this.eventDetail.id).remove();

            this.$bvToast.toast("Evento excluído com sucesso!", {
              title: "Sucesso",
              variant: "success",
              solid: true
            });
          });

          this.$bvModal.hide("mdlEventDetail");
        }
      });
    },

    getEventColor(status) {
      if (status === "confirmed") {
        return "#28a745";
      } else if (status === "tentative") {
        return "#ffc107";
      } else if (status === "cancelled") {
        return "#dc3545";
      }
    },

    messageConfig(chave, messages) {
      const msg = { ...messages[chave] };
      const msgAnterior = { ...messages[chave - 1] };

      let configuracoes = {
        tipo: "align-items-end",
        cor: "bg-light-success",
        conteudo: msg.conteudo
          .replace(/~(~*[^~\n]+~*)~/g, "<del>$1</del>")
          .replace(/_(_*[^_\n]+_*)_/g, "<em>$1</em>")
          .replace(/\*(\**[^*\n]+\**)\*/g, "<strong>$1</strong>")
          .replace(/`{3}(`*[^`\n]+`*)`{3}/g, '<code class="whatsapp">$1</code>')
          .replace(/@template\((.*)\)/, ""),
        conteudoCompleto: msg.conteudo_completo
          .replace(/~(~*[^~\n]+~*)~/g, "<del>$1</del>")
          .replace(/_(_*[^_\n]+_*)_/g, "<em>$1</em>")
          .replace(/\*(\**[^*\n]+\**)\*/g, "<strong>$1</strong>")
          .replace(/`{3}(`*[^`\n]+`*)`{3}/g, '<code class="whatsapp">$1</code>')
          .replace(/@template\((.*)\)/, ""),
        exibirCabecalho: true,
        exibirData: true
      };

      if (msg.tipo == "REC") {
        configuracoes.tipo = "align-items-start";
        configuracoes.cor = "bg-light-primary";

        if (msg.mensagem_id == 0) {
          configuracoes.cor = "bg-light-danger";
        }
      } else {
        if (msg.status == "ENV") {
          configuracoes.cor = "bg-light-success";
        } else if (msg.status == "PEN") {
          configuracoes.cor = "bg-light-warning";
        } else {
          configuracoes.cor = "bg-light-danger";
        }
      }

      if (msgAnterior == undefined || msg.tipo != msgAnterior.tipo) {
        configuracoes.exibirCabecalho = true;
      } else {
        configuracoes.exibirCabecalho = false;
      }

      if (msgAnterior == undefined) {
        configuracoes.exibirData = true;
      } else {
        const data = moment(msg.data);
        const dataAnterior = moment(msgAnterior.data);

        if (data.isAfter(dataAnterior, "day")) {
          configuracoes.exibirData = true;
        } else {
          configuracoes.exibirData = false;
        }
      }

      configuracoes.exibirImagem = true;

      return configuracoes;
    },

    sendMessage() {
      const phone = this.eventDetail.whatsapp.replace(/\D/g, "");
      const message = this.message;

      ApiService.post(
        `jpr/send-whatsapp-message/${phone}`,
        { message },
        {
          baseURL: process.env.VUE_APP_COMUNICANTE_API,
          headers: {
            Accept: "application/json"
          }
        }
      )
        .then((res) => {
          this.message = "";

          let messages = res.data.data;

          messages.map((msg, chave) => {
            msg.configuracoes = this.messageConfig(chave, messages);
          });

          this.eventDetail.messages = { ...messages };

          this.$bvToast.toast("Mensagem enviada com sucesso!", {
            title: "Sucesso",
            variant: "success",
            solid: true
          });
        })
        .then(() => {
          this.scrollChat();
        });
    },

    updateChat(phone, showLoading = false) {
      console.log("updateChat");
      return ApiService.get(
        `jpr/get-google-calendar-event-messages/${phone}`,
        "",
        showLoading,
        {
          baseURL: process.env.VUE_APP_COMUNICANTE_API,
          headers: {
            Accept: "application/json"
          }
        }
      )
        .then((response) => {
          let messages = response.data.data;

          messages.map((msg, chave) => {
            msg.configuracoes = this.messageConfig(chave, messages);
          });

          let event = { ...this.eventDetail };
          event.messages = { ...messages };

          this.eventDetail = event;
        })
        .then(() => {
          if (this.updateChatTimeout) {
            setTimeout(() => {
              this.updateChat(phone);
            }, 5000);
          }
        });
    },

    scrollChat() {
      const chat = document.getElementById("bodyChat");
      chat.scrollTo({
        top: chat.scrollHeight * 2,
        left: 0,
        behavior: "smooth"
      });
    },

    stopChatUpdate() {
      this.updateChatTimeout = false;
    }
  },

  watch: {
    showDeleted: function () {
      this.loadEvents();
    }
  }
};
</script>

<style scoped>
.modal-detalhes-corpo-secao-informacao {
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 1.33rem;
}

.corpo-secao-descricao {
  display: block;
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 0.9rem;
}

.corpo-secao-informacao {
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 1.33rem;
}
</style>
